#public-layout {
  background-color: white;
  width: 100%;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.public-logo-wrapper {
  position: absolute;
  top: 12px;
  left: 10px;
  width: 262px;
  display: flex;
  align-items: center;
  height: 51px;
  gap: 120px;
  z-index: 50;
  transition: all ease .15s;
  border-radius: 16px;
  padding-left: 20px;
  padding-top: 5px;

}

.public-icons-wrapper {
  width: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.public-new-chat-icon {
  font-size: 25px;
  cursor: pointer;

}


.public-open {
  margin-right: 75px;
  transition: all ease .15s;
  transform: rotate(0deg);
}


.public-content {
  padding-top: 50px;
  height: calc(100vh - 50px);
  overflow-y: auto;
  display: flex;
  justify-content: center;

  @supports (height: calc(100dvh - 50px)) {
    height: calc(100dvh - 50px);
  }
}

.public-hidden {
  visibility: hidden;
  pointer-events: none;
}

